<template>
  <v-container class="d-flex flex-column">
    <v-row v-if="title" style="padding-bottom: 5px;">
      <ActionTitleBar :title="title" :actions="buttonActions" />
    </v-row>
    <v-row style="padding-top: 5px; margin-bottom: 2rem">
      <DataList
        :tableHeader="tableHeader"
        :info="info"
        @emitFilter="emitFilter"
        :sortable="sortable"
        :is-loading="isLoading"
        :justify-center="justifyCenter"
        :tableData="paginatedData"
        :columnDecorators="columnDecorators"
        :columnActions="columnActions"
        :columnFunctions="columnFunctions"
        :showFilter="showFilter"
        :server-items-length="serverItemsLength"
        :orderItems="orderItems"
        :orderOptions="orderOptions"
        :filters="filters"
        :showTotalLine="showTotalLine"
        :totalLines="totalLines"
        :validateDeleteButton="validateDeleteButton"
        @changeItemsPerPage="changeItemsPerPage"
        @changeFormatterInput="changeFormatterInput"
        @changePage="changePage"
        @changeSort="changeSort"
      >
        <template v-if="hasSlotActionIcon" v-slot:action-icon="{ item }">
          <slot name="action-icon" :item="item"></slot>
        </template>
      </DataList>
    </v-row>
    <v-row>
      <v-dialog v-model="showDialog" :max-width="dialogWidth" :persistent="persistentCrudDialog">
        <v-card class="dialog-content">
          <v-card-title style="padding-top: 20px;">
            <h1 data-testid="page-title" class="description">
              {{ dialogTitle }}
            </h1>
            <v-spacer />
            <v-btn color="#07BEE7" icon dark @click="cancelForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions style="padding: 0 30px 30px 30px;">
            <v-btn color="#07BEE7" v-show="isEditing" outlined @click="confirmRemoveItem()">Excluir</v-btn>
            <v-spacer />
            <v-btn color="#07BEE7" @click="cancelForm()" outlined>Cancelar</v-btn>
            <v-btn color="#07BEE7" @click="subbmitForm()" outlined>Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <ConfirmActionAlert :show="deleting" v-on:recuse="cancelForm" v-on:accept="deleteDialog" />
    </v-row>
  </v-container>
</template>

<script>
import ActionTitleBar from '@/components/template/crud/ActionTitleBar';
import DataList from '@/components/template/list/DataList';
import ConfirmActionAlert from '@/components/template/action/ConfirmActionAlert';
import { routerPush } from '@/service/kplaceRouter';

export default {
  components: {
    ActionTitleBar,
    DataList,
    ConfirmActionAlert,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    justifyCenter: {
      type: Boolean,
      required: false,
      default: true,
    },
    entityName: {
      type: String,
      default: 'Unknow',
    },
    sortable: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableHeader: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableData: {
      type: Array,
      required: false,
      default: () => [],
    },
    enableAddFunction: {
      type: Boolean,
      default: false,
    },
    persistentCrudDialog: {
      type: Boolean,
      default: false,
    },
    addFunctionName: {
      type: String,
      default: '+ Add',
    },
    enableEditFunction: {
      type: Boolean,
      default: false,
    },
    enableRemoveFunction: {
      type: Boolean,
      default: false,
    },
    editIcon: {
      type: String,
      default: 'mdi-pencil',
    },
    editTitle: {
      type: String,
      default: 'Editar cadastro',
    },
    enableDetailsFunction: {
      type: Boolean,
      default: false,
    },
    columnDecorators: {
      type: Array,
      required: false,
      default: () => [],
    },
    columnActions: {
      type: Array,
      required: false,
      default: () => [],
    },
    otherActions: {
      type: Array,
      required: false,
      default: () => [],
    },
    formRoute: {
      type: String,
      required: false,
      default: null,
    },
    showFormDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    dialogWidth: {
      type: String,
      required: false,
      default: '800px',
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTotalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalLines: {
      type: Array,
      required: false,
      default: () => [],
    },
    info: {
      type: String,
      required: false,
      default: '',
    },
    orderItems: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    orderOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    serverItemsLength: {
      type: Number,
      default: -1,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: String,
    },
    routeWithVersion: {
      type: Boolean,
      default: false,
    },
    atributeToEdit: {
      type: String,
      required: false,
      default: '',
    },
    secoundAtributeToEdit: {
      type: String,
      required: false,
      default: '',
    },
    colorDelete: {
      type: String,
      required: false,
      default: '#EE0000',
    },
    validateDeleteButton: {
      type: Function,
      required: false,
      default: null,
    },
    enableAddCustomFunction: {
      type: Boolean,
      required: false,
      default: false,
    },
    customDataColumnFunctionAction: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      buttonActions: [],
      columnFunctions: [],
      dialogTitle: this.entityName,
      deleting: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    if (this.enableAddFunction) {
      const actionName = this.addFunctionName ? this.addFunctionName : 'Cadastrar '.concat(this.title);
      this.buttonActions.push({
        name: actionName,
        action: this.showAddForm,
      });
    }

    if (this.enableEditFunction) {
      this.columnFunctions.push({
        name: this.editTitle,
        icon: this.editIcon,
        color: '#07BEE7',
        function: this.showEditForm,
      });
    }

    if (this.enableRemoveFunction) {
      this.columnFunctions.push({
        name: 'Excluir',
        icon: 'mdi-delete',
        color: this.colorDelete,
        function: this.confirmRemoveItem,
        validateDeleteButton: this.validateDeleteButton,
      });
    }

    if (this.enableDetailsFunction) {
      this.columnFunctions.push({
        name: 'Detalhes',
        icon: 'mdi-magnify-plus-outline',
        color: '#07BEE7',
        function: this.showDetailsForm,
      });
    }
    if (this.enableAddCustomFunction) {
      this.columnFunctions.push(this.customDataColumnFunctionAction);
    }
  },
  computed: {
    formDialog: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('changeShowDialog', value);
      },
    },
    hasSlotActionIcon() {
      return !!this.$scopedSlots['action-icon'];
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.tableData.slice(startIndex, endIndex);
    },
  },
  methods: {
    changeFormatterInput(data) {
      this.$emit('changeFormatterInput', data);
    },
    emitFilter(data) {
      this.$emit('filter', data);
    },
    changeItemsPerPage(data) {
      this.itemsPerPage = data;
      this.currentPage = 1;
      this.$emit('changeItemsPerPage', data);
    },
    changePage(data) {
      this.currentPage = data;
      this.$emit('changePage', data);
    },
    changeSort(data) {
      this.$emit('changeSort', data);
    },
    showAddForm() {
      this.$emit('showAddForm');
      if (this.showFormDialog === true) {
        this.dialogTitle = 'Cadastro de '.concat(this.entityName);
        this.formDialog = true;
      } else {
        routerPush(this.formRoute);
      }
    },
    showEditForm(item) {
      this.$emit('editEvent', item);
      this.$emit('editAction', item);
      if (this.showFormDialog === true) {
        this.dialogTitle = 'Edição de '.concat(this.entityName);
      } else if (this.routeWithVersion) {
        routerPush(`${this.formRoute}/edit/${item.id.replace(/^(.+)( \d+)$/g, '$1')}/${item.version}`);
      } else if (this.atributeToEdit) {
        routerPush(`${this.formRoute}/edit/${item[this.atributeToEdit]}`);
      } else if (this.secoundAtributeToEdit) {
        routerPush(`${this.formRoute}/edit/${item.id}/${item[this.secoundAtributeToEdit]}`);
      } else {
        routerPush(`${this.formRoute}/edit/${item.id}`);
      }
    },
    showDetailsForm(item) {
      routerPush(`${this.formRoute}/detail/${item[this.atributeToEdit]}`);
    },
    confirmRemoveItem(item) {
      this.deleting = true;
      this.toDelete = item;
    },
    subbmitForm() {
      this.$emit('formSubbmit');
    },
    cancelForm() {
      this.deleting = false;
      this.$emit('formCancel');
    },
    deleteDialog() {
      this.deleting = false;
      this.$emit('formDelete', this.toDelete);
    },
  },
};
</script>

<style>
.dialog-content {
  background: #ededed 0 0 no-repeat padding-box !important;
  border-radius: 10px !important;
  opacity: 1;
}
</style>
